import { AgentFile } from 'src/app/portal/components/business/models/policy-v2.models';
import { FundingFrequencyIdEnum } from '../enums/policy-details.enum';
import { AgentSubAgent } from './agent.models';

export interface ExportPolicyListModel {
  IsInforceReport: boolean;
  AveragePremium?: number;
  TotalPremium?: number;
  AverageFaceAmount?: number;
  TotalFaceAmount?: number;
  AverageAnnualPremium?: number;
  TotalAnnualPremium?: number;
  Policies: Policy[];
}

export interface Policy {
  PolicyGuid: string | null;
  AgentGuid: string;
  FirstName: string | null;
  LastName: string | null;
  PolicyNumber: string | null;
  Clients: string | null
  Carrier: string | null;
  Product: string | null;
  AnnualizedPremium: number | null;
  Target: number | null;
  TotalFirstYearPremium: number;
  SubmittedDate: Date | null;
  PolicyStatus: string | null;
  PolicyStatusNote: string | null;
  PolicyStatusDate: Date | null;
  InforceDate: Date | null;
  FaceAmount: number | null;
  IsVariable: boolean | null;
  ActionNeeded: boolean | null;
  StatementDate: Date | null;
  CommissionAmount: number | null;
  SinglePremium: number;
  CurrentValue: number;
  LineOfBusinessName: string;
  LineOfBusinessId: number;
  HasChecklistItem?: boolean;
  IsPaidPolicy: boolean;
  Owners: string | null;
  JoinOwners: string | null;
  Annuitants: string | null;
  JointAnnuitants: string | null;
  Insureds: string | null;
  CoInsureds: string | null;
  AssociatedPortalAgentGUIDS: string | null;
  IssuedPremium: number | null;
  AccountValue: number | null;
  SurrenderEndDate: Date | null;
  SurrenderValue: number | null;
  SimonPolicyUrl?: string;
  IssuedCPCs: number;
  CPCs: number;
  ModalPremium?: number;
  TotalIssuedPremium?: number;
  ClientModels?: PolicyClientModel[];
  IssuedHealthClasses?: PolicyHealthClass[];
  AppliedHealthClasses?: PolicyHealthClass[];
  ClientResidentState?: string;
  IsPartial?: boolean;
  PaidPremiumCPCs: number;
  ModifiedOn?: Date | null;
}

export interface PolicyDetail {
  PolicyGuid: string;
  LineOfBusiness: string;
  LineOfBusinessId: number;
  QualificationType: string | null;
  PolicyNumber: string | null;
  Clients: string | null;
  Carrier: string;
  Product: string;
  SinglePremium: number;
  IssuedTotalFirstYearPremium: number;
  FaceAmount: number | null;
  Notes: CarrierNotes[];
  HasChecklistItem?: boolean;
  PolicyStatus: string | null;
  StageHistory: PolicyStage[];
  StageHistoryObject?: (PolicyStage | null)[];
  TaskInformation: PolicyRequirements[];
  CarrierRequirements: CarrierRequirements[];
  CaseManager: string | null;
  IsPartial: boolean | null;
  ModalPremium: number;
  TotalIssuedPremium?: number;
}

export interface CarrierNotes {
  Notes: string;
  CreatedOn: Date | null;
}

export interface PolicyStage {
  PolicyStageId: number;
  PolicyStage: string | null;
  PolicyStageDate: Date | null;
}

export interface InformalStage {
  InformalStageId: number;
  InformalStage: string | null;
  InformalStageDate: Date | null;
}

export interface PolicyRequirements {
  CaseManagerName: string | null;
  Description: string | null;
  ModifiedBy: string | null;
  ModifiedByFigAuthID: Date | null;
  ModifiedOn: Date | null;
  Name: string | null;
  Note: string | null;
  RequirementDate: Date | null;
  TaskID: number;
  TaskStatus: string | null;
}

export interface InformalSubCategory{
  Name: string;
  InformalRequirements: InformalRequirements[] | [];
}

export interface InformalRequirements extends PolicyRequirements {
  TaskSubcategory: string;
}

export interface CarrierRequirements {
  CarrierRequirement: string | null;
  Description: string | null;
  Status: string | null;
  AssignedTo: string | null;
  Note: string | null;
  RequestedDate: Date | null;
}

export interface PolicyFunding {
  PolicyGuid: string;
  Amount: number | null;
  Method: string | null;
  Frequency: string | null;
  FrequencyId: FundingFrequencyIdEnum;
  IssuedPremiumAmount: number | null;
  ModifiedBy: string | null;
  ModifiedOn: Date | null;
  IsFullyIssued: boolean | null;
}

export interface PolicySuitability {
  PolicyGuid: string;
  ModifiedBy: string | null;
  ModifiedOn: Date | null;
  IsSuitable: boolean;
}

export interface PolicyAgentUpload {
  FiledId: number;
  FileName: string | null;
  CreatedOn: Date | null;
  Type: string | null;
  Date: Date | null;
}

/**
 * @param TaskCategoryID ID to match against a preset list of Checklist categories, only used in Life/LTC V2/3 as of 05/03/2022
 * @param Files Agent file upload model containing ID that can be matched against a note sent together. TODO: Need a way to download the file (this is day 2 from 5/6/22)
 * @param CreatedOn even though we're sending Date obj to the backend, view model JSON will always render as string so opting for respecting actual data type here
 * @param ModifiedOn even though we're sending Date obj to the backend, view model JSON will always render as string so opting for respecting actual data type here
 */
export interface PolicyTask {
  PolicyGuid: string;
  TaskCategoryID: number;
  TaskId: number;
  TaskName: string | null;
  TaskStatus?: {
    Name: string;
    ID: number;
  };
  CreatedOn: string | null;
  ModifiedOn: string | null;
  Notes: Note[];
  Type: string | null;
  Date: Date | null;
  ActionNeeded: boolean | null;
  FileArray: File[];
  Message?: string;
}

/**
 * Extended from `PolicyTask` model
 * @param LatestCaseNote Latest update from FIG used to display on list view
 */
export interface PolicyTaskV2 extends PolicyTask {
  LatestCaseNote: Note;
}

/**
 * @param CreatedOn even though we're sending Date obj to the backend, view model JSON will always render as string so opting for respecting actual data type here
 * @param ModifiedOn even though we're sending Date obj to the backend, view model JSON will always render as string so opting for respecting actual data type here
 */
export interface Note {
  NoteId: number;
  Note: string | null;
  CreatedBy: string | null;
  CreatedOn: string | null;
  ModifiedOn: string | null;
  IsExternalUser: boolean;
  ReceivedByOperations: boolean;
  Type: string | null;
  Date: Date | null;
  Files: AgentFile[];
}

export interface PaperPolicyApplication {
  ClientFirstName: string | null;
  ClientLastName: string | null;
  PublicProductGuid: string | null;
  Notes: string;
}

// TODO Remove when My-Business is removed
export interface PolicyTable {
  Header: PolicyTableHeader[];
  Body: Policy[];
}

// TODO Remove when My-Business is removed
export interface InformalPolicyTable {
  Header: PolicyTableHeader[];
  Body: InformalPolicy[];
}


// TODO Remove when My-Business is removed
export interface PolicyTableHeader {
  DisplayName: string;
  ColumnName: string[];
  WidthClass: string;
  ReportTypes: string[];
}

export interface PolicyLob {
  Id: number;
  Name: string;
  Selected: boolean;
}

export class PolicyListFilter {
  SelectedLobIds: number[];
  SelectedAgentIds: string[];

  SelectedSubEntities: string[];
  IsClearingPolicies: boolean | null;
  ParentAgentGuid: string;

  StartDate: Date;
  EndDate: Date;
  AllLobIds: number[];
  AllAgentIds: (string | null)[];
  BaseStartDate: Date;
  BaseEndDate: Date;
  AllAgents?: AgentSubAgent[];
  CommisionGroupsFilterOn: boolean;

  constructor() {
    this.SelectedLobIds = [];
    this.SelectedSubEntities = [];
    this.SelectedAgentIds = [];
    this.IsClearingPolicies = null;
    this.ParentAgentGuid = '';
    this.StartDate = new Date(new Date().getFullYear(), (new Date().getMonth() - 12), new Date().getDate());
    this.EndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.AllLobIds = [];
    this.AllAgentIds = [];
    this.BaseStartDate = new Date(new Date().getFullYear(), (new Date().getMonth() - 12), new Date().getDate());
    this.BaseEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    this.AllAgents = [];
    this.CommisionGroupsFilterOn = false;
  }
}

// TODO: Rework export to void
export interface PolicyList {
  export: () => void;
  setSearchText: (searchText: string | null) => void;
  updateReportType: () => void;
  toggleSideNavMenu: () => void;
  policyListType: PolicyListTypeEnum;
}

export enum PolicyListTypeEnum {
  Pending = 'Pending',
  InforceLifecycle = 'Lifecycle',
  Informal = 'Informal',
  Disability = 'Disability',
  Wealth = 'Wealth',
  Cpc = 'CPC Reporting',
  Lifecycle = 'New Lifecycle'
}

export interface InformalPolicy {
  AgentGuid: string;
  Agents: string;
  AnnualizedPremium: number;
  AwaitingOffer: boolean;
  Carrier: string | null;
  FaceAmount: number;
  InformalGuid: string;
  NewWorkflow: boolean;
  RelatedPeople: string | null;
  State: string;
  StateName: string;
  Status: string | null;
}

export interface InformalPolicyV2 {
  InformalGuid: string;
  Carrier: string;
  CaseManager: string;
  Clients: string;
  FaceAmount?: number;
  LineOfBusiness: string;
  LineOfBusinessId: number;
  Product: string;
  PolicyStatus: string;
  HasChecklistItem: boolean;
  Notes: CarrierNotes[] | [];
  StageHistory: InformalStage[];
  State: string;
  StateName: string;
  TaskInformation: InformalRequirements[] | [];
}

export interface CarrierDetail {
  Carrier: string;
  HealthClassReason: string;
  HealthClasses: string;
  OfferDetails: string;
  PolicyNumber: string;
}

export interface InformalPolicyDetails {
  CarrierDetails: CarrierDetail[];
}

export interface InformalPolicyDetailsV2 {
  CarrierDetails: CarrierDetail[];
}

export interface InformalPolicyDetailsEntry {
  Entry: InformalPolicyDetails;
}

export interface InformalPolicyEntryV2 {
  Entry: InformalPolicyV2;
}

export interface RequirementStatus {
  Name: string;
  ID: number;
}

export interface PolicyClientModel {
  PersonId: number;
  FirstName: string;
  LastName: string;
  Dob: Date | null;
  PolicyRelationship: string;
  PolicyRelationshipId: number;
}

export interface PolicyHealthClass {
  HealthClass: string;
  HealthClassId: number;
  Notes: string;
}

export interface RequirementModalHeader {
  Carrier: string | null,
  Product: string | null,
  PolicyStatus: string | null
}